<template>
  <div class="VC2">
    <heads></heads>
    <div
      class="con_wrap"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="table"></div>
      <div class="rukou">
        <ul>
          <li><router-link to="/video/duomeiDetail?id=104"></router-link></li>
          <li><router-link to="/train/recruitStudents"></router-link></li>
          <li><router-link to="/train/baomingxuzhi"></router-link></li>
          <li><router-link to="/train/talentPool"></router-link></li>
          <li><router-link to="/train/zhengshuyangshi"></router-link></li>
          <!-- <li><router-link to="/user"></router-link></li> -->
          <li>
            <a
              target="_blank"
              href="https://www.sinology-k12.org.cn/traint"
            ></a>
          </li>
        </ul>
      </div>
      <div class="tab">
        <Swiper v-if="carouselsByCid.length > 0">
          <Slide v-for="(item, index) in carouselsByCid" :key="index">
            <img :src="item.copy" alt="" />
          </Slide>
        </Swiper>
      </div>
      <div class="btn">
        <router-link to="/train/signUp"></router-link>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { Swiper, Slide } from "vue-swiper-component";
import { getcarouselsByCid } from "@api/public";

export default {
  components: {
    heads,
    foot,
    Swiper,
    Slide,
  },
  data: function () {
    return {
      carouselsByCid: [],
    };
  },
  mounted: function () {
    let that = this;
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    getcarouselsByCid({ id: 2 }).then(function (res) {
      that.$set(that, "carouselsByCid", res.data);
    });
  },
  created: function () {
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
};
</script>
<style scoped>
.VC2 .con_wrap {
  width: 100%;
  height: 2472px;
  background: url(../../assets/images/trainBg2.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.VC2 .table {
  width: 1185px;
  height: 980px;
  margin: 0 auto;
  padding-top: 270px;
}
.VC2 .rukou {
  width: 1185px;
  height: 276px;
  margin: 0 auto;
}
.rukou > ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rukou > ul > li {
  width: 144px;
  height: 137px;
  border-radius: 50%;

  cursor: pointer;
}
.rukou > ul > li > a {
  display: block;
  width: 100%;
  height: 100%;
}
.VC2 .tab {
  width: 1185px;
  height: 710px;
  margin: 0 auto;
  border-radius: 10px;
}
.tab img {
  width: 100%;
  height: 710px;
  border-radius: 10px;
  background-size: cover;
}

.tab .wh_content {
  height: 100%;
}
.btn {
  width: 374px;
  height: 65px;
  margin: 0 auto;
  margin-top: 60px;
}
.btn > a {
  display: block;
  width: 100%;
  height: 100%;
}
</style>